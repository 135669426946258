/* Adjusted Styles */
.projects-header {
    font-size: 30px;  /* Increased header font-size */
    margin-bottom: 30px;
}

.project-item {
    border: 2px solid #e1e1e1;
    padding: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;  /* This ensures vertical centering of items */
    flex-wrap: wrap;
}

.project-logo img {
    max-width: 150px;  /* Increased logo width */
    margin-right: 30px;
    margin-bottom: 20px;
}

.project-info {
    flex-grow: 1;
    width: 60%;  /* Set width for the info section */
}

.project-title {
    font-size: 24px;  /* Increased title font-size */
    margin-bottom: 20px;
}

.project-description {
    margin-bottom: 20px;
    font-size: 18px;  /* Increased description font-size */
}

.project-tech .tech-tag {
    background-color: #f1f1f1;
    padding: 7px 12px;  /* Adjusted tag padding */
    margin-right: 8px;
    border-radius: 5px;
    font-size: 16px;  /* Increased tech-tag font-size */
}

.project-links a {
    text-decoration: none;
    color: blue;
    margin-right: 15px;  /* Increased margin */
    font-size: 18px;  /* Increased link font-size */
}
